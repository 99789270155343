<template>
  <div class="flex flex-col justify-center px-4 sm:px-6 lg:px-8">
    <div class="group mt-6 flex flex-col">
      <div class="flex-grow">
        <div
          class="relative overflow-hidden rounded-t-lg bg-[#818488] px-6 py-20 transition-transform duration-500 ease-in-out sm:px-10 sm:py-24 md:px-12 lg:px-20">
          <NuxtImg
            preload
            class="absolute inset-0 h-full w-full object-cover brightness-150"
            src="/images/padel-game-background.jpg"
            alt="Background image of a padel game"
            sizes="80vw md:90vw xl:70vw xxl:50vw" />
          <div class="absolute inset-0 bg-[#818488] mix-blend-multiply"></div>
          <div
            class="group-hover:scale-120 absolute -left-80 -top-56 blur-3xl transition-all duration-700 ease-in-out group-hover:-translate-x-20 group-hover:-translate-y-20"
            aria-hidden="true">
            <div
              class="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-celestial to-[#E3783B] opacity-[0.5]"
              style="
                clip-path: polygon(
                  74.1% 44.1%,
                  100% 61.6%,
                  97.5% 26.9%,
                  85.5% 0.1%,
                  80.7% 2%,
                  72.5% 32.5%,
                  60.2% 62.4%,
                  52.4% 68.1%,
                  47.5% 58.3%,
                  45.2% 34.5%,
                  27.5% 76.7%,
                  0.1% 64.9%,
                  17.9% 100%,
                  27.6% 76.8%,
                  76.1% 97.7%,
                  74.1% 44.1%
                );
              "></div>
          </div>
          <div class="relative mx-auto max-w-2xl transition-all lg:mx-0">
            <div class="flex items-center">
              <!-- <IconsTennis class="h-14 w-14 text-white mr-2" /> -->
              <h1 class="font-title text-7xl text-white">PadelCast</h1>
            </div>
            <figure>
              <blockquote
                class="mt-2 text-lg text-white sm:text-xl sm:leading-8">
                <p>
                  Con PadelCast, puoi registrare ogni partita per poi rivederla
                  e condividerla con gli amici, tutto in modo semplice e
                  divertente!
                </p>
              </blockquote>
            </figure>
          </div>
        </div>
      </div>
      <div class="rounded-lg bg-none shadow-md">
        <NuxtLink
          to="/courts"
          class="inline-flex w-full items-center justify-center rounded-b-lg border border-transparent bg-celestial px-0 py-3 font-title text-2xl text-white shadow-sm transition duration-150 ease-in-out hover:bg-celestialsec focus:outline-none">
          Registra la tua partita <span class="ml-2">→</span>
        </NuxtLink>
      </div>
    </div>

    <div v-if="loggedIn">
      <h1 class="mt-6 font-secondary text-3xl text-gray-700">
        Benvenuto {{ name }}!
      </h1>

      <h3 class="mt-4 text-base font-semibold leading-6 text-gray-900">
        Le tue statistiche
      </h3>
      <p class="text-sm text-gray-600">dalla data di iscrizione</p>
      <dl class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <div
          v-for="item in stats"
          :key="item.name"
          class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
          <dt class="truncate text-sm font-medium text-gray-500">
            {{ item.name }}
          </dt>
          <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
            {{ item.value }}
          </dd>
        </div>
      </dl>

      <div v-if="statsData?.lastMatch">
        <h3 class="mt-4 text-base font-semibold leading-6 text-gray-900">
          La tua ultima partita
        </h3>
        <div class="mb-6 mt-2 rounded-lg bg-white shadow-md">
          <MatchesCard :match="statsData.lastMatch" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
definePageMeta({
  auth: false,
  layout: 'navbar',
  title: 'Vivi il Padel, rivivilo con PadelCast',
})
useHead({
  titleTemplate: '%siteName %separator %s',
})

const { status } = useAuth()

const loggedIn = computed(() => status.value === 'authenticated')

const { data: authData } = useAuth()

const name = ref<string | null>('')

const { data: statsData, refresh: fetchStats } = useFetch('/api/user/stats', {
  immediate: false,
})

const stats = computed(() => [
  {
    name: 'Partite giocate',
    value: statsData.value?.totalMatches?.toString() ?? '0',
  },
  {
    name: 'Durata media',
    value: `${Math.round((statsData.value?.averageMatchLength ?? 0) / 60)} min`,
  },
  {
    name: 'Giocatori affrontati',
    value: statsData.value?.distinctPlayers?.toString() ?? '0',
  },
])

if (loggedIn.value === true) {
  name.value = authData.value?.user?.name ?? null
  await fetchStats()
}
</script>
